<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
        xl="2"
      >
        <v-subheader
          class="font-italic text-h6 justify-center aling-center d-flex"
          >Riepilogo dei conti</v-subheader
        >
        <InvoiceSummary :invoice="invoiceDynamic" :bus="invoiceSummaryBus" mode="new"></InvoiceSummary>
      </v-col>
      <v-divider
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        class="ma-10" vertical
      ></v-divider>
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        xl="9"
      >
        <v-subheader
          class="font-italic text-h6 justify-center aling-center d-flex"
          >Funzioni</v-subheader
        >
        <v-row>
          <v-col
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <v-card
              @click="openItemList"
              class="border-card mt-2 justify-center aling-center"
              :width="$vuetify.breakpoint.sm ? '22vw' : 
                $vuetify.breakpoint.xs ? '40vw' : '15vw'"
              heigth="15vh"
            >
              <v-card-text class="d-flex justify-center aling-center"
                ><div class="d-flex justify-center aling-center">
                  <v-icon class="mr-2" large>mdi-warehouse</v-icon>
                  Aggiungi prodotto da magazzino
                </div></v-card-text
              >
            </v-card>
          </v-col>
          <v-col
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <v-card
              @click="openServiceInvoiceDialog"
              class="border-card mt-2 justify-center aling-center"
              :width="$vuetify.breakpoint.sm ? '22vw' : 
                $vuetify.breakpoint.xs ? '40vw' : '15vw'"
              heigth="15vh"
            >
              <v-card-text class="d-flex justify-center aling-center"
                ><div class="d-flex justify-center aling-center">
                  <v-icon class="mr-2" large>mdi-text-box-plus-outline</v-icon>
                  Aggiungi servizi in fattura
                </div></v-card-text
              >
            </v-card>
          </v-col>
          <v-col 
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <v-card
              @click="openGenericElementDialog"
              class="border-card mt-2 justify-center aling-center"
              :width="$vuetify.breakpoint.sm ? '22vw' : 
                $vuetify.breakpoint.xs ? '40vw' : '15vw'"
              heigth="15vh"
            >
              <v-card-text class="d-flex justify-center aling-center"
                ><div class="d-flex justify-center aling-center">
                  <v-icon class="mr-2" large>mdi-text-box-plus-outline</v-icon>
                  Aggiungi elemento in fattura
                </div></v-card-text
              >
            </v-card>
          </v-col>
          <v-col
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <v-card
              @click="deleteInvoiceItem"
              class="border-card mt-2 justify-center aling-center"
              :width="$vuetify.breakpoint.sm ? '22vw' : 
                $vuetify.breakpoint.xs ? '40vw' : '15vw'"
              heigth="15vh"
            >
              <v-card-text class="d-flex justify-center aling-center"
                ><div class="d-flex justify-center aling-center">
                  <v-icon class="mr-2" large
                    >mdi-minus-box-multiple-outline</v-icon
                  >
                  Rimouvi tutti gli elementi in fattura
                </div></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div
              class="
                font-italic
                mb-2
                text-h6
                justify-center
                aling-center
                d-flex
              "
            >
              Riepilogo elementi
            </div>
            <div
              class="border-card"
              style="min-height: 50vh; max-height: 50vh; overflow-x: hidden"
              ref="dataTableWrapper"
            >
              <InvoiceItemDataTable
                v-model="selectedItems"
                @input="handleSelection"
                class="mt-10"
                :loading="loading"
                :items="invoiceElements"
                :headers="headerInvoiceItem"
                :select-on-row-click="false"
                :show-select="false"
                :show-actions="true"
                :page.sync="page"
                :row-per-page.sync="rowPerPage"
                :total-pages.sync="totalPages"
                :translator="translator"
                no-data-text="Selezionare almeno un elemento"
              >
                <template @click.stop="" v-slot:actions="{ item }">
                  <v-btn icon @click="addItem(item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn icon @click="removeItem(item)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn icon @click="deleteItem(item)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <template v-slot:custom-quantity="{ item }">
                  <v-chip color="secondary">
                    {{ item["quantity"] ? item["quantity"] : 0 }}</v-chip
                  >
                </template>
                <template v-slot:custom-discount="{ item }">
                  <v-text-field
                    class="mt-3 mb-3"
                    :style="{'min-width': '120px'}"
                    type="number"
                    min="0"
                    max="100"
                    filled
                    flat
                    rounded
                    hide-details="auto"
                    v-model="item['discount']"
                    :rules="[isNumeric]"
                  >
                  </v-text-field>
                </template>
                <template v-slot:custom-price="{ item }">
                  <v-chip color="primary">
                    €
                    {{
                      item["price"] ? Number(item["price"]).toFixed(2) : 0
                    }}</v-chip
                  >
                </template>
                <template v-slot:custom-discountedPrice="{ item }">
                  <v-chip color="primary">
                    €
                    {{
                      item["price"] && item["discount"]
                        ? Number(
                            Number(item["price"]) *
                              ((100 - Number(item["discount"])) / 100)
                          ).toFixed(2)
                        : Number(item["price"]).toFixed(2)
                    }}</v-chip
                  >
                </template>
                <template v-slot:custom-priceTot="{ item }">
                  <v-chip color="primary">
                    €
                    {{
                      item["price"] && item["discount"] && item["quantity"]
                      ? Number(
                            Number(item["price"]) *
                              ((100 - Number(item["discount"])) / 100)
                              * item["quantity"]
                          ).toFixed(2)
                        : Number(item["price"] * item["quantity"]).toFixed(2)
                    }}</v-chip
                  >
                </template>
                <template v-slot:custom-taxable="{ item }">
                  <v-chip color="secondary">
                    €
                    {{
                      item["price"] && item["discount"] && item["quantity"]
                        ? Number(
                            Number(item["price"]) *
                              ((100 - Number(item["discount"])) / 100)
                              * item["quantity"]
                              / ((100 + item["vatPercentage"]) / 100)
                          ).toFixed(2)
                        : Number(Number(item["price"]) * Number(item["quantity"]) / ((100 + Number(item["vatPercentage"])) / 100)).toFixed(2)
                    }}</v-chip
                  >
                </template>
                <template v-slot:custom-vat="{ item }">
                  <v-chip color="secondary">
                    €
                    {{
                      item["price"] && item["discount"] && item["quantity"]
                        ? Number(
                            Number(item["price"]) *
                              ((100 - Number(item["discount"])) / 100)
                              * item["quantity"]
                              / ((100 + item["vatPercentage"]) / 100)
                              * (item["vatPercentage"] / 100)
                          ).toFixed(2)
                        : Number(item["price"] * item["quantity"] * (item["vatPercentage"] / 100) / ((100 + item["vatPercentage"]) / 100)).toFixed(2)
                    }}</v-chip
                  >
                </template>
                <template v-slot:custom-tag="{item}">
                  {{ item["tags"] ? item["tags"][0].description : "Nessuna" }}
                </template>
                <template v-slot:custom-vatPercentage="{ item }">
                  <TaxesListSelector
                    v-model="item.vatPercentage"
                    :readonly="variant == 'detail' ? true : false"
                    @change="invoiceSummaryBus.$emit('calcVAtTotal')"
                  ></TaxesListSelector>
                </template>
              </InvoiceItemDataTable>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <ItemListDialog
      :open-dialog="openItemListDialog"
      @close="openItemListDialog = false"
      @add-items="handleAddItems"
      @error-quantity="handleError"
    >
    </ItemListDialog>
    <ServiceListDialog
      :open-dialog="openServiceListDialog"
      @close="openServiceListDialog = false"
      @add-service="handleAddService"
    >
    </ServiceListDialog>

    <GenericElementListDialog
      :open-dialog="openGenericElementListDialog"
      @close="openGenericElementListDialog = false"
      @add-element="handleAddGenericElement"
    >
    </GenericElementListDialog>

    <ErrorDialogComponent
      v-if="showErrorDialog"
      :open-dialog="showErrorDialog"
      title="Fattura"
      :error-message="errorMessage"
      type="error"
      @close-error="showErrorDialog = false"
    >
    </ErrorDialogComponent>
  </div>
</template>

<script>
import Vue from "vue";
import invoiceForm from "@/services/invoices/invoice.form.js";
import ErrorDialogComponent from "@/components/common/errorComponent/ErrorDialogComponent.vue";
import ListItemComponent from "@/components/common/ListItemComponent.vue";
import InvoiceItemDataTable from "@/components/common/TypeDataTable.vue";
import currentUser from "@/services/currentUser.service";
import ServiceListDialog from "@/components/areas/payments/invoices/components/ServiceListDialog.vue";
import GenericElementListDialog from "@/components/common/GenericElementListDialog.vue";
import ItemListDialog from "@/components/areas/payments/invoices/components/ItemListDialog.vue";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import RadioGroupComponent from "@/components/common/RadioGroupComponent.vue";
import invoiceService from "@/services/invoices/invoices.service.js";
import TaxesListSelector from "@/components/areas/payments/taxes/TaxesListSelector.vue"
import InvoiceSummary from "@/components/areas/payments/invoices/components/InvoiceSummary.vue";

export default {
  name: "InvoiceElementFormTab",
  components: {
    GenericElementListDialog,
    ServiceListDialog,
    InvoiceItemDataTable,
    ItemListDialog,
    ListItemComponent,
    ErrorDialogComponent,
    RadioGroupComponent,
    InvoiceSummary,
    TaxesListSelector,
  },

  props: {
    bus: {
      type: Object,
      default: () => new Vue(),
    },
    invoice: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: ''
    }
  },
  computed: {
    invoiceDynamic() {
      return {
        invoiceElements: this.invoiceElements
      };
    },
  },
  data: function () {
    return {
      errorMessage: "Completare di inserire i campi di fatturazione",
      showErrorDialog: false,
      amount: 0.0,
      internalCode: "",
      code: 0,
      codeInvoice: "",
      loading: false,
      formValid: false,
      invoiceElements: [],
      selectedItems: [],
      page: 1,
      rowPerPage: 50,
      totalPages: 1,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      openItemListDialog: false,
      openServiceListDialog: false,
      openGenericElementListDialog: false,
      invoiceSummaryBus: new Vue(),
      headerInvoiceItem: [
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Prezzo listino",
          value: "price",
          type: "custom",
        },
        {
          text: "Sconto %",
          value: "discount",
          type: "custom",
        },
        {
          text: "Prezzo scontato",
          value: "discountedPrice",
          type: "custom",
        },
        {
          text: "N° pz",
          value: "quantity",
          type: "custom",
        },
        {
          text: "Totale",
          value: "priceTot",
          type: "custom",
        },
        {
          text: "Aliquota IVA",
          value: "vatPercentage",
          type: "custom",
        },
        {
          text: "Imponibile",
          value: "taxable",
          type: "custom",
        },
        {
          text: "IVA",
          value: "vat",
          type: "custom",
        },
        {
          text: "tag",
          value: "tag",
          type: "custom",
        },
      ],
      taxForSaloon: undefined,
      qtyCheck: (qty) => {
        if (qty <= 0) {
          return itemVocabulary.Correct_Qty.desc;
        } else true;
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      max8CharRule: (v) => {
        if (v) {
          return v.length <= 8 || "Al massimo 8 caratteri";
        } else {
          return true;
        }
      },
      isNumeric: (v) => {
        return (Number(v) === 0 || (!!v && !isNaN(Number(v)))) || "Il valore deve essere un numero";
      }
    };
  },
  mounted() {
    if (this.$refs.dataTableWrapper) {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
      this.resizeListener = function () {
        this.dataTableHeightValue =
          this.$refs.dataTableWrapper.offsetHeight - 60;
      };
      this.resizeListener = this.resizeListener.bind(this);
      window.addEventListener("resize", this.resizeListener);
    }
    this.setUser();
    this.lastInternalCode();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    addItem(item) {
      this.checkItemAlreadyAdded(item, "items", "plus");
    },
    removeItem(item) {
      this.checkItemAlreadyAdded(item, "items", "minus");
    },
    deleteItem(item) {
      let invoiceItems = this.invoiceElements.filter(
        (invoiceItem) => item.description !== invoiceItem.description
      );

      let amounToRemove;
      invoiceItems.map(() => (amounToRemove = +item.price));
      this.amount = this.amount - amounToRemove;
      this.invoiceElements = invoiceItems;
    },
    handleError(errorMessage) {
      this.showErrorDialog = true;
      this.errorMessage = errorMessage;
    },
    handleAddItems(items) {
      this.populateInvoiceElements(items, "items", "plus");
      this.openItemListDialog = false;
    },
    populateInvoiceElements(items, type, action) {
      let amountToCalculate = Number(this.amount);
      items.map((item) => {
        if (!this.checkItemAlreadyAdded(item, type, action)) {
          let el = {};
          if (type === "items")
            el = {
              type: type,
              description: item.description,
              quantity: item.qtyToSell ? item.qtyToSell : 1,
              price: item.priceSelling,
              discount: 0,
              createdAt: item.createdAt,
              vatPercentage: item.vatPercentage != undefined ? item.vatPercentage : (this.taxForSaloon || 0),
              id: item.id,
              tags: item.tags,
            };
          else if (type === "services")
            el = {
              type: type,
              description: item.name,
              quantity: 1,
              price: item.price,
              discount: 0,
              createdAt: item.createdAt,
              vatPercentage: item.vatPercentage != undefined ? item.vatPercentage : (this.taxForSaloon || 0),
              id: item.id,
              tags: item.tags,
            };
          else if (type === "genericElements")
            el = {
              type: type,
              description: item.description,
              quantity: Number(item.quantity) || 1,
              price: item.price,
              discount: 0,
              createdAt: item.createdAt,
              vatPercentage: item.vatPercentage != undefined ? item.vatPercentage : (this.taxForSaloon || 0),
              tags: item.tags,
            };

          amountToCalculate += Number(el.price);
          this.invoiceElements.push(el);
        }
      });
      this.amount = Number(amountToCalculate);
    },
    checkItemAlreadyAdded(item, type, action) {
      let itemAlreadyAdded = {};
      if (type === "items" || type === "genericElements")
        itemAlreadyAdded = this.invoiceElements.find(
          (invoiceItem) => invoiceItem.description === item.description
        );
      else if (type === "services")
        itemAlreadyAdded = this.invoiceElements.find(
          (invoiceItem) => invoiceItem.description === item.name
        );
      if (itemAlreadyAdded) {
        let calculateAmount = Number(this.amount);
        if (action === "plus") {
          calculateAmount += Number(itemAlreadyAdded.price);
          itemAlreadyAdded.quantity++;
        } else if (action === "minus") {
          if (itemAlreadyAdded.quantity > 1) {
            calculateAmount -= Number(itemAlreadyAdded.price);
            itemAlreadyAdded.quantity--;
          }
        }
        this.amount = calculateAmount;
        return true;
      } else return false;
    },
    handleAddService(service) {
      this.populateInvoiceElements(new Array(service), "services", "plus");
    },
    handleAddGenericElement(genericElement) {
      this.populateInvoiceElements(
        new Array(genericElement),
        "genericElements",
        "plus"
      );
    },
    openServiceInvoiceDialog() {
      this.openServiceListDialog = true;
    },
    openGenericElementDialog() {
      this.openGenericElementListDialog = true;
    },
    openItemList() {
      this.openItemListDialog = true;
    },
    deleteInvoiceItem() {
      this.amount = [];
      this.invoiceElements = [];
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    handleSelection(itemsSelected) {
      this.selectedItems = itemsSelected;
    },
    setUser() {
      let userId = 0;
      currentUser.getUser().then((user) => {
        userId = user.id;
      });
    },
    updateObject(key, value) {
      invoiceForm.updateField(key, value);
    },
    lastInternalCode() {
      invoiceService.getLastInternalCode().then((lastInternalCode) => {
        if (lastInternalCode)
          this.internalCode =
            lastInternalCode.substring(0, 3) +
            (Number(lastInternalCode.substring(3, 9)) + 1);
        else this.internalCode = "FAT100001";
      });
    },
  },
  watch: {
    invoiceElements(newVal) {
      this.updateObject("invoiceElements", newVal);
    },
    page() {
      this.fetchItems({});
    },
    rowPerPage() {
      this.fetchItems({});
    },
    amount(newVal) {
      let val = newVal ? newVal : 0;
      this.amount = Number(val).toFixed(2);
      this.bus.$emit("update-amount", Number(newVal).toFixed(2));
    },
  },
  settings: {
    taxForSaloon: {
      bind: "taxForSaloon",
    },
  }
};
</script>

<style scoped>
.border-card {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #DCD8D8;
  transition: all 0.2s;
  transform: scale(1);
}
</style>