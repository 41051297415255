<template>
  <FullScreenDialog
    v-model="invoiceDialog"
    title="Nuova Fattura Emessa Da Incassare"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab in invoiceTabs" :key="tab.key">{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in invoiceTabs" :key="tabItem.key">
          <v-card flat>
            <component :bus="invoiceBus" :is="tabItem.component"></component>
             <div class="py-3" v-if="!!invoice && !!invoice.amount">
              <PartialPayment
                :amount="Number(invoice.amount)"
                :fromNewForm="true"
                @schedulingSettings="(newVal) => !!newVal ? schedulingSettings = newVal : schedulingSettings = undefined"
              ></PartialPayment>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="$router.push({ path: pathToGoBack })"
        >Chiudi</v-btn
      >
      <v-btn text color="default" :disabled="!formValid" @click="submitForm" v-if="!schedulingSettings" :loading="loading"
        >Registra</v-btn
      >
      <v-btn text color="default" :disabled="!formValid" @click="submitForm" v-else-if="!!schedulingSettings" :loading="loading"
        >Registra Con Rate</v-btn
      >
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import InvoiceElementFormTab from "@/components/areas/payments/invoices/form/InvoiceElementFormTab.vue";
import GeneralForm from "./form/GeneralFormTab.vue";
import invoiceService from "@/services/invoices/invoices.service.js";
import invoiceForm from "@/services/invoices/invoice.form.js";
import Vue from "vue";
import PartialPayment from "@/components/common/PartialPayment.vue";

export default {
  name: "InvoicesToPayNewForm",
  components: {
    FullScreenDialog,
    GeneralForm,
    InvoiceElementFormTab,
    PartialPayment,
  },
  data: function () {
    return {
      tab: null,
      invoiceDialog: this.openDialog,
      invoiceTabs: [
        {
          key: 0,
          title: "Dati di fatturazione",
          name: "general",
          component: "GeneralForm",
        },
        {
          key: 1,
          title: "Elementi in fattura",
          name: "elements",
          component: "InvoiceElementFormTab",
        },
      ],
      formValid: false,
      invoice: undefined,
      originalUrl: window.location.pathname,
      invoiceBus: new Vue(),
      schedulingSettings: undefined,
      loading: false,
    };
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/payments/invoicesToCash/list",
    },
    tabName: {
      type: String,
      default: "general",
    },
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName);

    let self = this;
    invoiceForm.resetInvoice();
    invoiceForm.on("update", function (data) {
      self.invoice = data.invoice;
    });

    invoiceForm.on("valid-change", function (data) {
      self.formValid = data;
    })    
    this.invoiceBus.$on('update-amount', self.autoCalculateAmount)
  },
  methods: {
    
    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.invoiceTabs.length; i++) {
        if (this.invoiceTabs[i].name == tabName) {
          this.tab = i;
          break;
        }
      }
    },
    submitForm() {
      let invoice = {};
      let customer = {};
      let supplier = {};
      let items = [];
      let services = [];
      let genericElements = [];
      if(!this.invoice.emittedAt) {
        this.$delegates.snackbar("Inserire la data di emissione");
        return;
      }
      if(!this.invoice.expirationDate) {
        this.$delegates.snackbar("Inserire la data di scadenza");
        return;
      }
      if(new Date(this.invoice.expirationDate).getTime() < new Date(this.invoice.emittedAt).getTime()) {
        this.$delegates.snackbar("La data di scadenza non può essere antecedente a quella di emissione!");
        return;
      }
      if(!this.invoice.type) {
        this.$delegates.snackbar("Inserire tipo di fattura");
        return;
      }  
      if(!this.invoice.amount || Number(this.invoice.amount) <= 0) {
        this.$delegates.snackbar("Inserire almeno un elemento in fattura. Ammontare totale fattura uguale a 0. ");
        return;
      }
       if(!this.invoice.supplierId) {
        this.$delegates.snackbar("Selezionare Azienda");
        return;
      }
      if (
        !!this.invoice.invoiceElements &&
        Array.isArray(this.invoice.invoiceElements) &&
        this.invoice.invoiceElements.length > 0
      ) {
        this.invoice.invoiceElements.map((item) => {
          if (item.type === "items") items.push(item);
          else if (item.type === "services") services.push(item);
          else if (item.type === "genericElements") genericElements.push(item);
        });
      }

      if (!!this.invoice.customerId) {
        customer = { id: this.invoice.customerId };
        delete this.invoice.supplierId;
        delete this.invoice.invoiceElements;
        delete this.invoice.customerId;
        invoice = this.invoice;

        this.loading = true;
        invoiceService.createAndAddCustomer(invoice, customer).then((result) => {
          this.addInvoiceElements(result, items, services, genericElements).then(
            (value) => {
              //Apertura Dialog -> Fattura creata -> Bottoni [Dettaglio, Lista]
              this.loading = false;
              this.$router.push({ path: "/payments/invoicesToCash/list" });
            }
          );
        }).catch((err) => {console.log(err)});
      }
      else if (!!this.invoice.supplierId) {
        supplier = { id: this.invoice.supplierId };
        delete this.invoice.supplierId;
        delete this.invoice.invoiceElements;
        delete this.invoice.customerId;
        invoice = this.invoice;

        this.loading = true;
        invoiceService.createAndAddSupplier(invoice, supplier).then(async(result) => {
          try {
            await this.addInvoiceElements(result, items, services, genericElements)
            if(!!this.schedulingSettings)
              await this.implementScheduling(result.id, this.schedulingSettings)
            this.loading = false;
            this.$router.push({ path: "/payments/invoicesToCash/list" });
          } catch (error) {
            console.log(error)
          }
        }).catch((err) => {console.log(err)});
      }
    },
    addInvoiceElements(createdInvoice, items, services, genericElements) {
      return new Promise((resolve, reject) => {
        if (!!createdInvoice) {
          if (!!items && Array.isArray(items) && items.length > 0) {
            invoiceService.addItems(createdInvoice, items).then((result) => {}).catch((err) => {console.log(err)});
          }
          if (!!services && Array.isArray(services) && services.length > 0) {
            invoiceService.addServices(createdInvoice, services).then((result) => {}).catch((err) => {console.log(err)});
          }
           if (!!genericElements && Array.isArray(genericElements) && genericElements.length > 0) {
            invoiceService.addGenericElements(createdInvoice, genericElements).then((result) => {}).catch((err) => {console.log(err)});
          }
          resolve();
        } else reject(new Error("invoice not created correctly"));
      });
    },
     /**
     * Implement scheduling payments if decided by user, called by this.submitForm()
     * @param {string} invoiceId
     * @param {Object} schedulingSettings 
     * @param {number} schedulingSettings.daysBetweenPayments   
     * @param {number} schedulingSettings.numberOfPayments   
     * @param {Date} schedulingSettings.expiryFirstPaymentDate  
     */
    async implementScheduling(invoiceId, schedulingSettings){
      try {
        let paymentsScheduled = []
        for (let i = 1; i <= schedulingSettings.numberOfPayments; i++){
          let obj = {}
          obj.amount = Number(this.invoice.amount/schedulingSettings.numberOfPayments).toFixed(2) 
          if(i==1)
            obj.expireDate = schedulingSettings.expiryFirstPaymentDate
          else{
            obj.expireDate = new Date(paymentsScheduled[i-2].expireDate.getTime())  
            obj.expireDate.setDate(obj.expireDate.getDate() + Number(schedulingSettings.daysBetweenPayments))
          }
          obj.type = 'invoice' 
          paymentsScheduled = [...paymentsScheduled, obj]  
        }
        await invoiceService.schedulePayments(invoiceId, paymentsScheduled)
      } catch (error) {
        console.log(error)
        throw new Error(error)
      } 
    },
    autoCalculateAmount(amount){
      this.invoiceBus.$emit('handle-update-amount', amount)
    }

  },
  watch: {
    openDialog(newVal) {
      this.invoiceDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState(
          "Tab",
          "Tab",
          this.originalUrl + "?tabName=" + this.invoiceTabs[newVal].name
        );
      } catch (err) {
        console.log("try setting the tab name but something goes wrong");
      }
    },
  },
  computed: {},
};
</script>